import loadable from '@loadable/component';
import retry from './retry';

export const About = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/about-us')),
);
export const AdminOrders = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/admin/orders')),
);
export const BlogCategory = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/blog-posts/category/BlogCategory.res.js'),
  ),
);
export const BlogPostNew = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/blog-posts/new/BlogPostNew.res.js')),
);
export const BlogPostEdit = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/blog-posts/edit/BlogPostEdit.res.js')),
);
export const BlogPostShow = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/blog-posts/show/BlogPostShow.res.js')),
);
export const BlogPostsIndex = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/blog-posts/index/BlogPostsIndex.res.js'),
  ),
);
export const BlogPostsDashboard = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/blog-posts/dashboard/BlogPostsDashboard.res.js'),
  ),
);
export const BlogPostsDashboardAnalytics = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/blog-posts/analytics/BlogPostsDashboardAnalytics.res.js'
    ),
  ),
);
export const BlogPostCategoriesDashboard = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/blog-post-categories/dashboard/BlogPostCategoriesDashboard.res.js'
    ),
  ),
);
export const BlogPostCategoryNew = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/blog-post-categories/new/BlogPostCategoryNew.res.js'
    ),
  ),
);
export const BlogPostCategoryEdit = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/blog-post-categories/edit/BlogPostCategoryEdit.res.js'
    ),
  ),
);
export const CartReservationComplete = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/cart-reservation-complete/CartReservationComplete.res.js'
    ),
  ),
);
export const ChangePassword = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../bundles/Client/components/ChangePassword')),
);
export const Contact = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/contact/Contact.res.js')),
);
export const CreateBareMetalProjectConfiguration = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-bare-metal-project-configuration'
    ),
  ),
);
export const CreateBareMetalProjectProvidersSelection = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-bare-metal-project-providers-selection'
    ),
  ),
);
export const CreateBareMetalProjectGeneralInformation = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-bare-metal-project-general-information'
    ),
  ),
);
export const CreateCloudProjectCloudTypeSelection = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-cloud-project-cloud-type-selection'
    ),
  ),
);
export const CreateCloudProjectGeneralInformation = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-cloud-project-general-information'
    ),
  ),
);
export const CreateCloudProjectIaasCloudServersConfiguration = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-cloud-project-iaas-cloud-servers-configuration'
    ),
  ),
);
export const CreateCloudProjectIaasCloudStorageConfiguration = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-cloud-project-iaas-cloud-storage-configuration'
    ),
  ),
);
export const CreateCloudProjectProvidersSelection = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-cloud-project-providers-selection'
    ),
  ),
);
export const CreateColocationRfpProjectConfiguration = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-colocation-rfp-project-configuration'
    ),
  ),
);
export const CreateColocationRfpProjectGeneralInformation = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-colocation-rfp-project-general-information'
    ),
  ),
);
export const CreateColocationRfpProjectLocations = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-colocation-rfp-project-locations'
    ),
  ),
);
export const CreateInternetRfpProjectConfiguration = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-internet-project-configuration'
    ),
  ),
);
export const CreateInternetRfpProjectGeneralInformation = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-internet-rfp-project-general-information'
    ),
  ),
);
export const CreateInternetRfpProjectProvidersSelection = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-internet-rfp-project-providers-selection'
    ),
  ),
);
export const CreateNetworkProjectGeneralInformation = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-network-project-general-information'
    ),
  ),
);
export const CreateNetworkProjectMplsNetworkConfiguration = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-network-project-mpls-network-configuration'
    ),
  ),
);
export const CreateNetworkProjectNetworkTypeSelection = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-network-project-network-type-selection'
    ),
  ),
);
export const CreateNetworkProjectPrivateLineConfiguration = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-network-project-private-line-configuration'
    ),
  ),
);
export const CreateNetworkProjectProvidersSelection = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-network-project-providers-selection'
    ),
  ),
);
export const CreateNetworkProjectSdWanNetworkConfiguration = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/create-network-project-sdwan-network-configuration'
    ),
  ),
);
export const DashboardCreateProduct = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/dashboard-create-product')),
);
export const DashboardEditProductAssetsTab = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/dashboard-edit-product/assets-tab')),
);
export const DashboardEditProductGeneralTab = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/dashboard-edit-product/general-tab')),
);
export const DashboardEditProductLocationTab = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/dashboard-edit-product/location-tab')),
);
export const DashboardProducts = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/dashboard-products')),
);
export const DashboardCreateProvider = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/dashboard-create-provider/DashboardCreateProvider.res.js'
    ),
  ),
);
export const DetailsSidebar = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/product-detail/details-sidebar/DetailsSidebar.res.js'
    ),
  ),
);
export const Footer = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "footer" */ '../rescript/styleguide/components/Footer/Footer.res.js'
    ),
  ),
);
export const ForgotPassword = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../bundles/Client/components/ForgotPassword')),
);
export const HomeIndex = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/home/index')),
);
export const InviteSignUp = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/client/invite-sign-up')),
);
export const LeadConfirmed = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/lead-confirmed/LeadConfirmed.res.js')),
);
export const LeadConfirmedBareMetal = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/lead-confirmed/lead-types/LeadConfirmedBareMetal.res.js'
    ),
  ),
);
export const LeadConfirmedZenlayerBareMetal = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/lead-confirmed/lead-types/LeadConfirmedZenlayerBareMetal.res.js'
    ),
  ),
);
export const LeadConfirmedEnzuBareMetal = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/lead-confirmed/lead-types/LeadConfirmedEnzuBareMetal.res.js'
    ),
  ),
);
export const LeadConfirmedHivelocityBareMetal = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/lead-confirmed/lead-types/LeadConfirmedHivelocityBareMetal.res.js'
    ),
  ),
);
export const LeadConfirmedEvocativeBareMetal = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/lead-confirmed/lead-types/LeadConfirmedEvocativeBareMetal.res.js'
    ),
  ),
);
export const LeadConfirmedColocation = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/lead-confirmed/lead-types/LeadConfirmedColocation.res.js'
    ),
  ),
);
export const LeadConfirmedGeneral = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/lead-confirmed/lead-types/LeadConfirmedGeneral.res.js'
    ),
  ),
);
export const LeadConfirmedMulticloud = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/lead-confirmed/lead-types/LeadConfirmedMulticloud.res.js'
    ),
  ),
);
export const LocationProviderProductsSlider = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/provider-detail/product-slider/LocationProviderProductsSlider.res.js'
    ),
  ),
);
export const LocationMap = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/common/marketplace/LocationMap.res.js'),
  ),
);
export const LocationsIndex = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/locations/index/LocationsIndex.res.js'),
  ),
);
export const LocationShow = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/locations/show')),
);
export const LocationsDashboard = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/locations/dashboard/index/LocationsDashboard.res.js'
    ),
  ),
);
export const LocationBrochuresDashboard = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/location-brochures/dashboard/index/LocationBrochuresDashboard.res.js'
    ),
  ),
);
export const LocationBrochuresIndex = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/location-brochures/index/LocationBrochuresIndex.res.js'
    ),
  ),
);
export const LocationBrochureShow = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/location-brochures/show')),
);
export const LocationBrochureNew = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/location-brochures/dashboard/new/LocationBrochureNew.res.js'
    ),
  ),
);
export const LocationBrochureEdit = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/location-brochures/dashboard/edit/LocationBrochureEdit.res.js'
    ),
  ),
);
export const LocationsIndexMap = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/locations/index/components/LocationsIndexMap.res.js'
    ),
  ),
);

export const LocationShowContentMap = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/locations/show/components/main-content/components/map/components/LocationShowContentMap.res.js'
    ),
  ),
);

export const MarketplaceIndex = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/product-index/marketplace-index')),
);
export const ModalHeader = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/navigation/ModalHeader.res.js')),
);
export const Navigation = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "navigation" */ '../rescript/styleguide/components/navigation'
    ),
  ),
);
export const PrivacyPolicy = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/privacy-policy')),
);
export const ProductCreateReservation = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/product-reservation/form')),
);
export const ProductDescription = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/product-detail/product-description/ProductDescription.res.js'
    ),
  ),
);
export const ProductGrid = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/product-index/product-grid/ProductGrid.res.js'),
  ),
);
export const ProductReservationComplete = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/product-reservation/confirm')),
);
export const ProductSpecs = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/product-detail/product-specs/ProductSpecs.res.js'
    ),
  ),
);
export const ProductTile = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/product-index/product-tile')),
);
export const ProductView = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/product-detail/product-detail-view')),
);
export const ProviderShow = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/providers/show')),
);
export const ProviderEdit = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/provider-edit')),
);
export const ProviderLocations = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/providers/locations/ProviderLocations.res.js'),
  ),
);
export const ProviderProjectProposalForm = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/provider-project-proposal-form'
    ),
  ),
);
export const ProvidersList = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/providers-list')),
);
export const ProvidersDashboard = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/providers/dashboard/index/ProvidersDashboard.res.js'
    ),
  ),
);
export const ProviderBrochuresDashboard = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/provider-brochures/dashboard/index/ProviderBrochuresDashboard.res.js'
    ),
  ),
);
export const ProviderBrochureNew = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/provider-brochures/dashboard/new/ProviderBrochureNew.res.js'
    ),
  ),
);
export const ProviderBrochureEdit = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/provider-brochures/dashboard/edit/ProviderBrochureEdit.res.js'
    ),
  ),
);
export const ProviderBrochureShow = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/provider-brochures/show')),
);
export const ProviderBrochuresIndex = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/provider-brochures/index/ProviderBrochuresIndex.res.js'
    ),
  ),
);
export const ResendConfirmation = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../bundles/Client/components/ResendConfirm')),
);
export const ResourceDetail = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/resources/resource-detail')),
);
export const ResourceLanding = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/resources/resource-landing')),
);
export const ResourceProviders = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/resources/resource-providers/ResourceProviders.res.js'
    ),
  ),
);
export const ResourcesDashboard = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/resources/resources-dashboard/ResourcesDashboard.res.js'
    ),
  ),
);
export const ResourcesAnalytics = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/resources/resources-analytics/ResourcesAnalytics.res.js'
    ),
  ),
);
export const ResourceCategoriesDashboard = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/resources/resource-categories-dashboard'),
  ),
);
export const ResourceNew = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/resources/resource-new')),
);
export const ResourceEdit = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/resources/resource-edit')),
);
export const SignIn = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/client/sign-in')),
);
export const SignUp = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/client/sign-up')),
);
export const Sitemap = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/sitemap')),
);
export const Terms = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/terms-of-use/Terms.res.js')),
);
export const ThanksForContact = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/contact/ThanksForContact.res.js')),
);
export const ThanksForDownload = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/resources/thanks-for-download/ThanksForDownload.res.js'
    ),
  ),
);
export const ThanksForSignup = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/client/thanks/ThanksForSignup.res.js')),
);
export const ThanksForSignupProvider = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/client/thanks/ThanksForSignupProvider.res.js'),
  ),
);
export const UserCloudIaasServerProject = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-cloud-iaas-server'),
  ),
);
export const UserCloudIaasStorageProject = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-cloud-iaas-storage'),
  ),
);
export const UserColocationRfpProject = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-colocation-rfp-project'
    ),
  ),
);
export const UserInternetRfpProject = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-internet-rfp-project'
    ),
  ),
);
export const UserBareMetalProject = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-bare-metal-project'),
  ),
);
export const UserEditProfile = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/edit-profile')),
);
export const UserInvitations = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/invitations')),
);
export const UsersDashboard = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/users/dashboard/index/UsersDashboard.res.js'),
  ),
);
export const UserListCloudRack = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/admin/users-cloudrack')),
);
export const UserNetworkMplsProject = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-network-mpls-project'
    ),
  ),
);
export const UserNetworkPrivateLineProject = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-network-private-line-project'
    ),
  ),
);
export const UserNetworkSdWanProject = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-network-sdwan-project'
    ),
  ),
);
export const UserOrders = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/orders')),
);
export const UserProjectProposal = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-project-proposal'),
  ),
);
export const UserProjectProposals = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-project-proposals'),
  ),
);
export const UserProjectProviders = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-project-providers'),
  ),
);
export const UserProjects = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/projects/user-projects'),
  ),
);
export const UserCloudRack = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/cloudrack')),
);
export const UserDashboardEdit = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/users/dashboard/edit/UserDashboardEdit.res.js'),
  ),
);
export const UserDashboardNotifications = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/user-dashboard/notifications/UserDashboardNotifications.res.js'
    ),
  ),
);
export const Multicloud = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/services/multicloud')),
);
export const MulticloudMap = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/services/multicloud/components/map/MapMulticloud.res.js'
    ),
  ),
);
export const Colocation = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/services/colocation')),
);
export const ZenlayerBareMetalCloudMap = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/services/bare-metal-cloud/zenlayer/components/map/MapZenlayerBareMetal.res.js'
    ),
  ),
);
export const HivelocityBareMetalCloudMap = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/services/bare-metal-cloud/hivelocity/components/map/MapHivelocityBareMetal.res.js'
    ),
  ),
);
export const EvocativeBareMetalCloudMap = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/services/bare-metal-cloud/evocative/components/map/MapEvocativeBareMetal.res.js'
    ),
  ),
);
export const BareMetalIndex = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/services/bare-metal-cloud/index/BareMetalIndex.res.js'
    ),
  ),
);
export const ZenlayerBareMetal = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/services/bare-metal-cloud/zenlayer')),
);
export const EnzuBareMetal = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/services/bare-metal-cloud/enzu')),
);
export const HivelocityBareMetal = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/services/bare-metal-cloud/hivelocity')),
);
export const EvocativeBareMetal = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ '../rescript/bundles/services/bare-metal-cloud/evocative')),
);
export const EnzuBareMetalMap = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/services/bare-metal-cloud/enzu/components/map/EnzuBareMetalMap.res.js'
    ),
  ),
);
export const CountriesIndex = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/locations/countries/index/CountriesIndex.res.js'),
  ),
);
export const CountryShow = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/locations/countries/show/CountryShow.res.js'),
  ),
);
export const StatesIndex = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/locations/states/index/StatesIndex.res.js'),
  ),
);
export const StateShow = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/locations/states/show/StateShow.res.js'),
  ),
);
export const CitiesIndex = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/locations/cities/index/CitiesIndex.res.js'),
  ),
);
export const CityShow = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/locations/cities/show/CityShow.res.js'),
  ),
);
export const CountriesDashboard = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/countries/dashboard/index/CountriesDashboard.res.js'
    ),
  ),
);
export const CountryEdit = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/countries/dashboard/edit/CountryEdit.res.js'),
  ),
);
export const StatesDashboard = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/states/dashboard/index/StatesDashboard.res.js'),
  ),
);
export const StateEdit = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/states/dashboard/edit/StateEdit.res.js'),
  ),
);
export const CitiesDashboard = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/cities/dashboard/index/CitiesDashboard.res.js'),
  ),
);
export const CityEdit = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/cities/dashboard/edit/CityEdit.res.js'),
  ),
);
export const ProviderCountryShow = loadable(() =>
  retry(() =>
    import(
      /* webpackPrefetch: true */ '../rescript/bundles/providers/countries/show/ProviderCountryShow.res.js'
    ),
  ),
);
export const ProviderStateShow = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/providers/states/show/ProviderStateShow.res.js'),
  ),
);
export const ProviderCityShow = loadable(() =>
  retry(() =>
    import(/* webpackPrefetch: true */ '../rescript/bundles/providers/cities/show/ProviderCityShow.res.js'),
  ),
);
